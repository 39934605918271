<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="page--login d-flex justify-center align-center">
          <div class="form-container">
            <v-card class="pa-10" color="rgba(255, 255, 255, .8)">
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col class="text-center">
                    <img
                    src="/img/logo.svg" alt="brasão-home-64px"
                    />
                    <br />
                    <h1
                      class="subtitle-1 blue--text text--darken-4"
                      v-text="app.name"
                    ></h1>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.username"
                      solo
                      hide-details="auto"
                      placeholder="CPF"
                      v-mask="'###.###.###-##'"
                      :rules="regrasDeNomeDeUsuario"
                      @keyup.enter="enter"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.password"
                      type="password"
                      solo
                      placeholder="Senha"
                      hide-details="auto"
                      :rules="passwordRules"
                      @keyup.enter="enter"
                      :maxlength="8"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn block color="success" :loading="loading" @click.prevent="enter">
                      Entrar
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0 mt-3 text-center">
                    <small>
                      <a @click="esqueciMinhaSenha()">
                        Esqueci a senha
                      </a>
                    </small>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0 mt-3 text-center">
                    <small>
                      <a @click="naoTenhoSenhaCadastrada()">
                        Não tenho senha cadastrada
                      </a>
                    </small>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { AUTH_NAMESPACE, LOGIN, UPDATE_MENU, UPDATE_ABILITIES } from "store/modules/auth";
import { CLEAR } from "store";
import configurations from "commons/configurations";
import pageLoadingMixin from "mixins/page-loading";
import notificationMixin from "mixins/notification";
import Validations from "services/validations/validations.js";
// import { ConvertPermissionsIntoAbilities } from "services/abilities/ability";

const { mapActions: authActions } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "Login",
  mixins: [pageLoadingMixin, notificationMixin],
  components: {},
  data: () => ({
    valid: false,
    loading: false,
    app: {
      name: configurations.app.alias || "Acessar o Sistema",
    },
    user: {
      username: undefined,
      password: undefined,
    },
  }),
  computed: {
    regrasDeNomeDeUsuario() {
      return [(value) => Validations.required(value, "CPF é obrigatório")];
    },
    passwordRules() {
      return [(value) => Validations.required(value, "Senha é obrigatória")];
    },
  },
  beforeMount() {
    this.hidePageLoading();
    this.$store.dispatch(CLEAR);
  },
  methods: {
    ...authActions([LOGIN, UPDATE_MENU, UPDATE_ABILITIES]),
    enter() {
      this.$refs.form.validate();

      if (!this.valid) {
        return;
      }

      this.loading = true;

      this.$api()
        .post(configurations.api.auth.authenticateUri, {
          nomeDeUsuario: this.user.username,
          senha: this.user.password,
        })
        .then(({ data }) => {
          if (data.sucesso) {
            return this[LOGIN](data);
          } else {
            this.loading = false;
            this.notifyError(data.mensagem);
          }
        })
        .then(() => {
          this.$router.push({ path: "/app" });
        })
        .catch(({ response }) => {
          this.loading = false;

          this.$store.dispatch(CLEAR);

          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError(
              "Não conseguimos nos comunicar com o servidor da aplicação."
            );
          }
        });
    },
    esqueciMinhaSenha() {
      window.open("https://www.senhaservidor.sad.pe.gov.br/ControleSenha/senha_edit.jsf", "_blank");
    },
    naoTenhoSenhaCadastrada() {
      this.notifyWarning("Você será redirecionado ao Portal do Servidor, para cadastro da senha de acesso.");
      setTimeout(() => {
        window.open("https://www.senhaservidor.sad.pe.gov.br/ControleSenha/servidor_edit.jsf", "_blank");
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.page--login {
  min-height: calc(100vh - 24px) !important;
  .form-container {
    form {
      min-width: 250px;
    }
  }
}
</style>
